import React from "react";
import HeroText from "3dComponents/HeroText";
import ProjectCard from "./ProjectCard";
import { Float } from "@react-three/drei";

const data = [
  {
    name: "Almond",
    orderId: 1,
    startDate: "01/06/2022",
    endDate: "01/01/2023",
    thumbnail: "/images/projects/almond2.png",
    device: "desktop",
    stack: ["REACT", "NODEJS", "MONGODB"],
    teamSize: 2,
    link: "https://mentostar.com",
    github: "",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magnam quod est quisquam sint amet porro aut repellat fuga, tempora voluptatem quidem ea tenetur sed consequatur illum atque impedit non cum.",
  },

  {
    name: "Pricemate",
    orderId: 6,
    startDate: "01/06/2022",
    endDate: "01/01/2023",
    thumbnail: "/images/projects/pricemate.png",
    device: "desktop",
    stack: ["HTML", "CSS", "JS", "PHP"],
    teamSize: 2,
    company: "Pricemate Online Services Pvt. Ltd.",
    link: "https://mentostar.com",
    github: "",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magnam quod est quisquam sint amet porro aut repellat fuga, tempora voluptatem quidem ea tenetur sed consequatur illum atque impedit non cum.",
  },

  {
    name: "Mentostar",
    orderId: 2,
    startDate: "01/06/2022",
    endDate: "01/01/2023",
    thumbnail: "/images/projects/mentostar.png",
    device: "mobile",
    stack: ["REACT", "NODEJS", "MONGODB"],
    teamSize: 2,
    company: "Applore Technologies Pvt. Ltd.",
    link: "https://mentostar.com",
    github: "",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magnam quod est quisquam sint amet porro aut repellat fuga, tempora voluptatem quidem ea tenetur sed consequatur illum atque impedit non cum.",
  },

  {
    name: "Discount Khojo",
    orderId: 5,
    startDate: "01/06/2022",
    endDate: "01/01/2023",
    thumbnail: "/images/projects/discountkhojo.webp",
    device: "mobile",
    stack: ["HTML", "CSS", "JS", "PHP"],
    teamSize: 2,
    company: "Pricemate Online Services Pvt. Ltd.",
    link: "https://mentostar.com",
    github: "",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magnam quod est quisquam sint amet porro aut repellat fuga, tempora voluptatem quidem ea tenetur sed consequatur illum atque impedit non cum.",
  },

  {
    name: "Social Networking",
    orderId: 3,
    startDate: "01/06/2022",
    endDate: "01/01/2023",
    thumbnail: "/images/projects/sacredspot.png",
    device: "mobile",
    stack: ["HTML", "CSS", "JS", "PHP"],
    teamSize: 2,
    link: "https://mentostar.com",
    github: "",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magnam quod est quisquam sint amet porro aut repellat fuga, tempora voluptatem quidem ea tenetur sed consequatur illum atque impedit non cum.",
  },

  {
    name: "Grocery Delivery",
    orderId: 4,
    startDate: "01/06/2022",
    endDate: "01/01/2023",
    thumbnail: "/images/projects/sitapuriya.png",
    device: "mobile",
    stack: ["KOTLIN", "PHP"],
    teamSize: 2,
    link: "https://mentostar.com",
    github: "",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magnam quod est quisquam sint amet porro aut repellat fuga, tempora voluptatem quidem ea tenetur sed consequatur illum atque impedit non cum.",
  },

  // {
  //   name: "Blobinator",
  //   orderId: 7,
  //   startDate: "01/06/2022",
  //   endDate: "01/01/2023",
  //   thumbnail: "/images/projects/blobinator2.png",
  //   device: "desktop",
  //   stack: ["JQUERY", "CSS", "JS"],
  //   teamSize: 2,
  //   link: "https://mentostar.com",
  //   github: "",
  //   description:
  //     "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magnam quod est quisquam sint amet porro aut repellat fuga, tempora voluptatem quidem ea tenetur sed consequatur illum atque impedit non cum.",
  // },
];

function Projects(props) {
  return (
    <group position={[8, 0, 2]}>
      <Float>
        <HeroText
          text="My Projects"
          font="/res/fonts/league-spartan-v6-latin-800.woff"
          fontSize={0.3}
        />
      </Float>
      {/* <ProjectCard project={data[1]} position={[0, 0, -2]} /> */}
    </group>
  );
}

export default Projects;
