export default Object.freeze({
  BACKEND_URL: {
    LOCAL: "http://localhost:3013",
    DEV: "https://api.prathamvaidya.in",
    PRODUCTION: "https://api.prathamvaidya.in",
  },
  ENV: "production",
  NAME: "Pratham Vaidya",
  INSTAGRAM: "pratham_vaidya",
  GA4_MEASUREMENT_ID: "G-4CB1EJDKT1",
});
