import React from "react";
import Loader from "./almond/Loader";

function LoadingScreen(props) {
  return (
    <div className="w-screen h-screen bg-background flex items-center justify-center">
      <Loader colored={true} className="heartbeat" />
    </div>
  );
}

export default LoadingScreen;
