import React, { useEffect, useRef, useState } from "react";
import * as CANNON from "cannon-es";
import { useFrame, useThree } from "@react-three/fiber";
import CannonDebugger from "cannon-es-debugger";
import * as THREE from "three";
import { KeyboardControls, useKeyboardControls } from "@react-three/drei";

const physicsWorld = new CANNON.World();
physicsWorld.gravity.set(0, -9.8, 0); // Gravity pulls things down
// physicsWorld.solver.iterations = 100;

const groundBody = new CANNON.Body({
  type: CANNON.Body.STATIC,
  shape: new CANNON.Plane(),
  material: new CANNON.Material({
    friction: 1, // set friction to 1 (maximum value)
  }),
});

groundBody.position.y = -1;
groundBody.quaternion.setFromEuler(-Math.PI / 2, 0, 0);
physicsWorld.addBody(groundBody);

const SKILLS = [
  {
    name: "NodeJS",
    icon: "/images/icons/square/nodejs.png",
    color: 0x388e3c,
  },

  {
    name: "React",
    icon: "/images/icons/square/react.png",
    color: 0x00e2ff,
  },

  {
    name: "Javascript",
    icon: "/images/icons/square/nodejs.png",
    color: 0x388e3c,
  },

  {
    name: "HTML",
    icon: "/images/icons/square/nodejs.png",
    color: 0x388e3c,
  },

  {
    name: "CSS",
    icon: "/images/icons/square/nodejs.png",
    color: 0x388e3c,
  },

  {
    name: "PHP",
    icon: "/images/icons/square/nodejs.png",
    color: 0x388e3c,
  },

  {
    name: "Express",
    icon: "/images/icons/square/nodejs.png",
    color: 0x388e3c,
  },

  {
    name: "MongoDB",
    icon: "/images/icons/square/nodejs.png",
    color: 0x388e3c,
  },

  {
    name: "MySQL",
    icon: "/images/icons/square/nodejs.png",
    color: 0x388e3c,
  },

  {
    name: "ThreeJS",
    icon: "/images/icons/square/nodejs.png",
    color: 0x388e3c,
  },

  {
    name: "C++",
    icon: "/images/icons/square/nodejs.png",
    color: 0x388e3c,
  },

  {
    name: "Linux",
    icon: "/images/icons/square/nodejs.png",
    color: 0x388e3c,
  },

  {
    name: "AWS",
    icon: "/images/icons/square/nodejs.png",
    color: 0x388e3c,
  },

  {
    name: "Typescript",
    icon: "/images/icons/square/nodejs.png",
    color: 0x388e3c,
  },
];

const KeyboardMapping = [
  {
    name: "up",
    keys: ["w"],
  },
  {
    name: "down",
    keys: ["s"],
  },
  {
    name: "left",
    keys: ["a"],
  },
  {
    name: "right",
    keys: ["d"],
  },

  {
    name: "yUp",
    keys: ["z"],
  },

  {
    name: "yDown",
    keys: ["x"],
  },

  {
    name: "space",
    keys: ["f"],
  },
];

// const BOX_CREATOR_BOARD_DEFAULT_POSITION = [0, 0, 0];
const BOX_CREATOR_BOARD_DEFAULT_POSITION = [1, 1, 1.5];

function Skills(props) {
  const spotLight = useRef();
  const boxCreatePosition = useRef(BOX_CREATOR_BOARD_DEFAULT_POSITION);
  const cubes = useRef([]);
  const { scene } = useThree();
  const cannonDebugger = useRef();

  useEffect(() => {
    if (scene) {
      cannonDebugger.current = new CannonDebugger(scene, physicsWorld);
    }
  }, [scene]);

  useEffect(() => {
    // scene.add(new THREE.SpotLightHelper(spotLight.current));
    // create cubes automatically

    const cubeInterval = setInterval(() => {
      if (cubes.current.length == SKILLS.length) {
        clearInterval(cubeInterval);
        return;
      }
      createCube({
        skill: SKILLS[cubes.current.length],
        position: boxCreatePosition.current,
      });
    }, 2000);

    return () => {
      clearInterval(cubeInterval);
    };
  }, []);

  const createCube = ({ skill, position = [1, 5, 2], size = 0.2 }) => {
    // create physics box

    console.debug(
      "Creating Cube with position = ",
      position,
      " , size= ",
      size
    );

    if (!skill) {
      skill = {
        name: "",
        icon: undefined,
        color: 0xffc300,
      };
    }

    var cubeShape = new CANNON.Box(
      new CANNON.Vec3(size / 2, size / 2, size / 2)
    );

    var cubeBody = new CANNON.Body({
      mass: 1,
      shape: cubeShape,
      material: new CANNON.Material({
        friction: 1, // set friction to 1 (maximum value)
      }),
    });

    // cubeBody.solverIterations = 100; // Increase iterations
    // cubeBody.solverTolerance = 0.001; // Decrease tolerance

    cubeBody.position.set(position[0], position[1], position[2]);

    // create rendering box

    var texture;

    if (skill.icon) {
      var textureLoader = new THREE.TextureLoader();
      texture = textureLoader.load(skill.icon);
    }

    var cubeGeometry = new THREE.BoxGeometry(size, size, size);
    var cubeMaterial = new THREE.MeshStandardMaterial({
      map: texture,
      //   color: SKILLS[cubes.current.length].color,
      emissive: skill.color,
      emissiveIntensity: 1,
      metalness: 1,
      roughness: 0,
    });

    var cubeMesh = new THREE.Mesh(cubeGeometry, cubeMaterial);
    cubeMesh.position.set(position[0], position[1], position[2]);

    // Add in physics world and 3d world

    physicsWorld.addBody(cubeBody);
    scene.add(cubeMesh);

    // save states for extra control
    cubes.current.push({
      physicsBody: cubeBody,
      geometry: cubeGeometry,
      material: cubeMaterial,
      mesh: cubeMesh,
    });
  };

  // debugger setup

  useFrame((state, delta) => {
    // physicsWorld.fixedStep();
    var fixedTimeStep = 1.0 / 60.0; // 60 Hz

    physicsWorld.step(fixedTimeStep, delta, 10);
    cannonDebugger.current.update();

    // update position in 3d cubes
    for (let cube of cubes.current) {
      cube.mesh.position.copy(cube.physicsBody.position);
      cube.mesh.quaternion.copy(cube.physicsBody.quaternion);
    }
  });

  return (
    <KeyboardControls map={KeyboardMapping}>
      {/* <group position={[-2.5, 0, 2.5]}> */}
      <CubeCreatorBoard
        positionRef={boxCreatePosition}
        createCube={() => createCube({ position: boxCreatePosition.current })}
      />

      {/* <spotLight
        color={0xf60000}
        position={[1, -1, 1]}
        intensity={1}
        distance={2}
        ref={spotLight}
      ></spotLight> */}
      {/* </group> */}
    </KeyboardControls>
  );
}

export default Skills;

function CubeCreatorBoard({ positionRef, createCube }) {
  const [sub, get] = useKeyboardControls();
  const board = useRef();
  const lastSpace = useRef(false);

  useFrame(() => {
    const key = get();

    if (key.up && board.current.position.x < 2) {
      board.current.position.x += 0.01;
    }

    if (key.down && board.current.position.x > -2.5) {
      board.current.position.x -= 0.01;
    }

    if (key.left && board.current.position.z > 1) {
      board.current.position.z -= 0.01;
    }

    if (key.right && board.current.position.z < 2.5) {
      board.current.position.z += 0.01;
    }

    if (key.yUp && board.current.position.y < 0.5) {
      board.current.position.y += 0.01;
    }

    if (key.yDown && board.current.position.y > -0.5) {
      board.current.position.y -= 0.01;
    }

    if (key.space && !lastSpace.current) {
      createCube();
    }

    lastSpace.current = key.space;

    positionRef.current = [
      board.current.position.x,
      board.current.position.y,
      board.current.position.z,
    ];
  });

  return (
    <mesh position={BOX_CREATOR_BOARD_DEFAULT_POSITION} ref={board}>
      <boxGeometry args={[0.2, 0.01, 0.2]} />
      <meshPhongMaterial color={0xffffff} />
    </mesh>
  );
}
