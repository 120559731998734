import { Canvas, useFrame, useThree } from "@react-three/fiber";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Lights from "3dComponents/Lights";
import {
  OrbitControls,
  ScrollControls,
  useCursor,
  useScroll,
} from "@react-three/drei";
import HeroPage from "./HeroPage";
import Projects from "3dComponents/Projects";
import Skills from "components/3d/Skills";

const MINIMAL_REQUIRED_MOVEMENT = 50;

const pages = ["DEFAULT", "SKILLS", "PROJECTS"];

const CAMERA_POSITIONS = {
  DEFAULT: {
    x: 0,
    y: 0,
    z: 1.5,
    rotationX: 0,
    rotationY: 0,
    rotationZ: 0,
  },

  // DEFAULT: {
  //   x: 1.5,
  //   y: 0,
  //   z: 0.5,
  //   rotationX: 0,
  //   rotationY: Math.PI / 3,
  //   rotationZ: 0,
  // },

  SKILLS: {
    x: -2.5,
    y: 0,
    z: 2.5, // 2.5
    rotationX: 0,
    rotationY: -Math.PI / 3, // -60 deg
    rotationZ: 0,
  },

  // PROJECTS: {
  //   x: 7,
  //   y: 0,
  //   z: 4.5,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  // },

  PROJECTS: {
    x: -2.5,
    y: -4,
    z: 2.5,
    rotationX: 0,
    rotationY: -Math.PI / 3, // -60 deg
    rotationZ: 0,
  },

  //   XYZ: {
  //     x: 0,
  //     y: 0,
  //     z: 2.5,
  //     rotationX: 0,
  //     rotationY: -Math.PI / 3,
  //     rotationZ: 0,
  //   },
};

function CanvasElements() {
  const { camera } = useThree();
  const [page, setPage] = useState(0);
  useCursor(true);
  const lastCameraIndex = useRef(0);
  const scroll = useScroll();

  function lerp(start, end, weight) {
    return start + weight * (end - start);
  }

  function getPageOffset(offset, pages) {
    // offset -> 0 to 1
    // pages -> Total Pages greater than 0

    if (offset <= 0) return 0;
    if (pages < 1) pages = 1;

    var pageSize = 1 / pages;
    var currentPage = parseInt(offset / pageSize);
    var lowerBound = currentPage * pageSize;

    return lerp(0, 1, (offset - lowerBound) / pageSize); // Page Offset
  }

  function getCurrentPageIndexByOffset(offset, pages) {
    if (offset <= 0) return 0;
    return parseInt(offset / (1 / pages)); // Current Page
  }

  useFrame((state, delta) => {
    const pageOffset = getPageOffset(scroll.offset, scroll.pages);
    const currentPageIndex = getCurrentPageIndexByOffset(
      scroll.offset,
      scroll.pages
    );

    var scrollDirection = 1;

    if (currentPageIndex != lastCameraIndex.current) {
      if (lastCameraIndex.current < currentPageIndex) scrollDirection = 1;
      else scrollDirection = -1;
      // save latest page index
      lastCameraIndex.current = currentPageIndex;
      setPage(currentPageIndex + 1);
      document.getElementById("pageIndex").innerText = currentPageIndex + 1; // temp
    }

    const targetPageIndex = currentPageIndex + 1; // next page (Not sure why this is working but its working)

    if (targetPageIndex > 0 && targetPageIndex < pages.length) {
      camera.position.x = lerp(
        CAMERA_POSITIONS[pages[currentPageIndex]].x,
        CAMERA_POSITIONS[pages[targetPageIndex]].x,
        pageOffset
      );
      camera.position.y = lerp(
        CAMERA_POSITIONS[pages[currentPageIndex]].y,
        CAMERA_POSITIONS[pages[targetPageIndex]].y,
        pageOffset
      );
      camera.position.z = lerp(
        CAMERA_POSITIONS[pages[currentPageIndex]].z,
        CAMERA_POSITIONS[pages[targetPageIndex]].z,
        pageOffset
      );
      camera.rotation.y = lerp(
        CAMERA_POSITIONS[pages[currentPageIndex]].rotationY,
        CAMERA_POSITIONS[pages[targetPageIndex]].rotationY,
        pageOffset
      );
    }
  });

  return (
    <>
      <Lights />

      <HeroPage />

      {(page > 0 || true) && <Skills />}

      <Projects />
      {/* <HeroText
          position={[0, -0.3, -0.5]}
          text=""
          font="/res/fonts/league-spartan-v6-latin-800.woff"
          color="white"
          fontSize={0.8}
        /> */}

      {/* <group position={[0, 0, 0]}>
          <mesh
            key="shadow-catcher"
            receiveShadow
            position={[0, 0, 0]}
            rotation-x={-Math.PI / 2}
          >
            <planeGeometry attach="geometry" args={[5, 5]} />
            <shadowMaterial attach="material" transparent opacity={0.2} />
          </mesh>
        </group> */}

      {/* <OrbitControls /> */}
    </>
  );
}

function CanvasView(props) {
  // const { camera } = useThree();
  const container = useRef();

  const [cameraPosition, setCameraPosition] = useState(
    CAMERA_POSITIONS.DEFAULT
  );

  const handleMouseMove = useCallback(
    (e) => {
      return;
      if (
        e.movementX > -1 * MINIMAL_REQUIRED_MOVEMENT &&
        e.movementX < MINIMAL_REQUIRED_MOVEMENT &&
        e.movementY > -1 * MINIMAL_REQUIRED_MOVEMENT &&
        e.movementY < MINIMAL_REQUIRED_MOVEMENT
      ) {
        return; // skip if movement is minimum
      }

      const alpha = cameraPosition.x + (e.movementX % 5) / 100;
      const beta = cameraPosition.y + (e.movementY % 5) / 100;

      // console.debug("alpha=", alpha, " beta=", beta);

      setCameraPosition({
        ...cameraPosition,
        x: alpha,
        y: beta,
      });
    },
    [cameraPosition]
  );

  return (
    <div className="bg-background h-screen w-screen overflow-hidden">
      <Canvas
        shadows
        camera={{
          fov: 50,
          position: [cameraPosition.x, cameraPosition.y, cameraPosition.z],
          near: 0.01,
        }}
        resize={{ scroll: true, debounce: { scroll: 50, resize: 0 } }}
        ref={container}
        onMouseMove={handleMouseMove}
      >
        <ScrollControls pages={3} damping={0.25}>
          {/* <Scroll> */}
          <CanvasElements cameraPosition={cameraPosition} />
          {/* </Scroll> */}
        </ScrollControls>
      </Canvas>
      <div
        className="fixed bottom-10 right-10 text-white text-xl font-hero font-bold"
        id="pageIndex"
      >
        1
      </div>
    </div>
  );
}

export default CanvasView;

// useGLTF.preload("/model.glb")
