import React from "react";

function Loader({ colored = true, className }) {
  return (
    <img
      src={"/images/heartbeat" + (colored ? "-colored" : "") + ".svg"}
      className={"w-12 h-12 " + className}
    />
  );
}

export default Loader;
