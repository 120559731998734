import React, { lazy, Suspense } from "react";
import WebFont from "webfontloader";
import { Routes, Route, useLocation } from "react-router-dom";
import ErrorPage from "views/ErrorPage";
import { AnimatePresence } from "framer-motion";
// import AlmondPage from "views/AlmondPage";
import CanvasView from "views/CanvasView";
import LoadingScreen from "components/LoadingScreen";
const AlmondPage = lazy(() => import("views/AlmondPage"));
const HomePage = lazy(() => import("views/HomePage"));

function App(props) {
  const location = useLocation();

  React.useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Montserrat:300,400",
          "Open Sans:300,400",
          "League Spartan:300,400,800",
          "Indie Flower:400",
        ],
      },
    });
  });

  return (
    <AnimatePresence>
      <Suspense fallback={<LoadingScreen />}>
        <Routes location={location} key={location.pathname}>
          <Route index element={<HomePage />} />
          <Route path="almond" element={<AlmondPage />} />
          <Route path="date" element={<AlmondPage />} />
          {/* <Route path="home" element={<CanvasView />} /> */}
          <Route path="*" element={<ErrorPage code={404} />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
}

export default App;
