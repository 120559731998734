import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./styles/index.css";
import ReactGA from "react-ga4";
import Config from "./config/index";
import { HelmetProvider } from "react-helmet-async";

ReactGA.initialize(Config.GA4_MEASUREMENT_ID);
// ReactGA.send({
//   hitType: "pageview",
//   page: document.location.pathname,
// });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);
