import { useFrame, useGraph, useLoader } from "@react-three/fiber";
import React, { useEffect, useMemo, useRef } from "react";
import { AnimationMixer } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";

function AvatarModel({ src, animationSrc, position = [0, 0, 0] }) {
  const { scene } = useLoader(GLTFLoader, src);
  const modelRef = useRef();
  const { nodes, materials } = useGraph(scene);
  const animationModel = useLoader(FBXLoader, animationSrc);
  const animationMixer = useMemo(() => {
    const mixer = new AnimationMixer(nodes.Armature);

    mixer.clipAction(animationModel.animations[0]).play();
    mixer.update(0);

    return mixer;
  }, [animationModel.animations, nodes.Armature]);

  useEffect(() => {
    // temp disable frustum culled
    modelRef.current.traverse(function (obj) {
      obj.frustumCulled = false;
    });
    modelRef.current.rotation.y = -0.5;
  }, []);

  useFrame((clock, delta) => {
    animationMixer?.update(delta);
    // console.debug(modelRef.current.children[0].children[0].children[3]);
    // let rotation = 50 * (Math.PI / 180);
    // if (modelRef?.current) {
    //   currentRotation += delta * 0.2;
    //   // modelRef.current.rotation.y = rotation + Math.sin(currentRotation) / 3;
    //   // console.debug(head);
    // }
  });

  return (
    <mesh ref={modelRef} position={position}>
      <primitive object={scene} />
    </mesh>
  );
}

export default AvatarModel;
