import { Text } from "@react-three/drei";
import React from "react";

function HeroText({
  position = [0, 0, 0],
  text,
  font,
  fontSize,
  color = "white",
  outlined = false,
  rotateX = 0,
  rotateY = 0,
  rotateZ = 0,
}) {
  return (
    <Text
      //   maxWidth={1}
      lineHeight={1}
      letterSpacing={0.02}
      anchorX="center"
      anchorY="middle"
      color={color}
      fillOpacity={outlined ? 0 : 1}
      strokeWidth={outlined ? "2.5%" : ""}
      strokeColor={color}
      position={position}
      fontSize={fontSize}
      font={font}
      rotation-x={rotateX}
      rotation-y={rotateY}
      rotation-z={rotateZ}
    >
      {text}
    </Text>
  );
}

export default HeroText;
