import React from "react";
import HeroText from "3dComponents/HeroText";
import { Float } from "@react-three/drei";
import AvatarModel from "../3dComponents/AvatarModel";

const AVATAR_MODEL_SOURCE = "/res/models/avatar.glb";
const AVATAR_MODEL_ANIMATIONS = {
  IDLE: "/res/models/anims/idle_breathing.fbx",
};

function HeroPage(props) {
  return (
    <group>
      <Float>
        <HeroText
          position={[0, 0.4, -0.5]}
          text="Pratham"
          font="/res/fonts/league-spartan-v6-latin-800.woff"
          color="#ed4264"
          fontSize={0.8}
          outlined
        />

        <HeroText
          position={[0, -0.4, -0.5]}
          text="Vaidya"
          font="/res/fonts/league-spartan-v6-latin-800.woff"
          color="#ed4264"
          fontSize={0.8}
        />
      </Float>

      <AvatarModel
        src={AVATAR_MODEL_SOURCE}
        animationSrc={AVATAR_MODEL_ANIMATIONS.IDLE}
        position={[0.7, -1.3, 0]}
      />
    </group>
  );
}

export default HeroPage;
