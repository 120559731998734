import React from "react";
import { Environment } from "@react-three/drei";

function Lights(props) {
  return (
    <>
      {/* <Environment preset="city" /> */}
      <ambientLight color="#fff5b6" intensity={0.25} />

      <directionalLight
        color="#002aff"
        intensity={5}
        castShadow
        position={[-3, 5, -5]}
      />

      <directionalLight
        color="#ed4264"
        intensity={5}
        castShadow
        position={[-3, 5, -5]}
      />

      <spotLight
        color="#fff5b6"
        position={[12, 10, 7.5]}
        angle={0.314}
        intensity={1}
        distance={0}
      />
    </>
  );
}

export default Lights;
